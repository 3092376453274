var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value && _vm.value.synopticComponent)?_c('div',{key:_vm.value.id,staticClass:"me"},[('data_id' in _vm.value)?_c('TogglePanel',{attrs:{"title":_vm.$tc('data', 1),"icon":{
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down',
      before: 'glyphicon glyphicon-stats'
    },"persistent":"synoptic_control_data_selection"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{class:_vm.value.data_id ? '' : 'text-danger',attrs:{"title":_vm.dataName || _vm.$tc('required', 1)}},[_vm._v(" "+_vm._s(_vm.$tc("data", 1))+" "),(!_vm.value.data_id)?_c('i',{staticClass:"fa fa-exclamation-triangle",attrs:{"title":`${_vm.$t('invalid_data')}\n${_vm.$tc('required', 1)}`}}):_vm._e()])]},proxy:true}],null,false,4018459107)},[(_vm.value && _vm.value.id)?_c('ControlDataSelector',{key:_vm.value.id,attrs:{"value":_vm.value.data_id,"connectorId":_vm.value.connector_id,"parser":_vm.dataParser,"allowedTypes":_vm.allowedTypes},on:{"input":_vm.updateDataId,"setExpression":function($event){return _vm.buildExpression($event)}}}):_vm._e(),(_vm.dataValueIndex)?_c('ValueSourceSelector',{attrs:{"offset":true,"connectorId":_vm.selectedDataConnectorId,"exclude":[_vm.value.data_id]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('label',{attrs:{"for":"data_value_index"}},[_vm._v(_vm._s(_vm.$t("titles.data_value_index"))+" "),_c('ToolTip',{attrs:{"title":`${_vm.$t('hints.data_value_index')}<br/>${_vm.$tc(
                  'interval',
                  1
                )}: &ge; 0 && &le; ${_vm.dataMemorySize - 1}`}})],1),_c('div',{staticClass:"pull-right"},[(_vm.dataValueIndex.type == 'constant')?_c('span',{staticClass:"small",attrs:{"title":`${_vm.$t('numeric').toLowerCase()}`}},[_vm._v(" ≥ 0 && ≤ "+_vm._s(_vm.dataMemorySize - 1)+"    ")]):_c('span',{staticClass:"small",attrs:{"title":`${_vm.$t('numeric').toLowerCase()} ${_vm.$t(
                  'or'
                ).toLowerCase()} ${_vm.$t(
                  'expression'
                ).toLowerCase()}\nex: $value*10+3`}},[_c('i',{staticClass:"fa fa-code"})])])])]},proxy:true}],null,false,3815333823),model:{value:(_vm.dataValueIndex),callback:function ($$v) {_vm.dataValueIndex=$$v},expression:"dataValueIndex"}}):_vm._e(),_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("simulation")))]),(_vm.value.data_id)?_c('TestDataValue',{key:_vm.testDataValue.counter,attrs:{"dataId":_vm.value.data_id,"dataValueIndex":_vm.dataValueIndex,"disabled":_vm.testDataValue.disabled}}):_vm._e(),(_vm.$attrs.hasExpression)?_c('div',[_c('ControlExpressionForm',{ref:"expForm",attrs:{"dataId":_vm.value.data_id,"hint":"hints.expression_warning"},model:{value:(_vm.expression),callback:function ($$v) {_vm.expression=$$v},expression:"expression"}})],1):_vm._e()],1):_vm._e(),(_vm.value.synopticComponent && _vm.value.synopticComponent.style)?_c('ControlStyleProperties',{staticStyle:{"margin-bottom":"12px"},attrs:{"value":_vm.value.synopticComponent.style,"control":_vm.value,"applicable":true},on:{"input":function($event){return _vm.update({
        ..._vm.value,
        synopticComponent: {..._vm.value.synopticComponent, style: $event}
      })}},scopedSlots:_vm._u([(_vm.$slots.padding)?{key:"padding",fn:function(){return [_vm._t("padding")]},proxy:true}:null],null,true)}):_vm._e(),_c('TogglePanel',{attrs:{"title":"info","icon":{
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down',
      before: 'fa fa-info'
    },"persistent":"synoptic_control_info"}},[_c('div',{staticClass:"form-group form-group-sm"},[_c('label',{staticClass:"label-inline",attrs:{"for":"editor-text-align"}},[_vm._v(_vm._s(_vm.$t("description"))+" "),_c('ToolTip',{attrs:{"title":`${_vm.$t('hint')},
                  ${_vm.$t('description')}, ${_vm.$t('info')}`}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hint),expression:"hint"}],staticClass:"form-control",attrs:{"placeholder":`${_vm.$t('text')}`},domProps:{"value":(_vm.hint)},on:{"input":function($event){if($event.target.composing)return;_vm.hint=$event.target.value}}})])]),_c('TogglePanel',{attrs:{"title":"permissions","icon":{
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down',
      before: 'fa fa-key'
    },"persistent":"synoptic_control_permissions"}},[_c('ProcessAreaPermissions',{attrs:{"id":"process-area-permissions"},model:{value:(_vm.permissions),callback:function ($$v) {_vm.permissions=$$v},expression:"permissions"}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }